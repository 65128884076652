import { useContext } from 'react';

import { GlobalContext } from '../context/GlobalProvider';
import api from './api';
import { getFirestore, doc, setDoc, getDocs, collection, getDoc } from 'firebase/firestore';
import firebase_app from 'src/firebase-app';
import uuidv4 from 'src/utils/uuidv4';
import { fDate, fDateTime } from 'src/utils/format-time';

export default function CustomerService() {
  let { setCustomers, customerList, setCustomerNames } = useContext(GlobalContext);
  let collectionName = 'Customers_1';
  const db = getFirestore(firebase_app);

  const getCustomers = async (data, errorMsg) => {
    const collectionRef = collection(db, collectionName);
    const snapshot = await getDocs(collectionRef);
    const data2 = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCustomers(data2);
    return data2;
  };

  const getCustomer = async (id, errorMsg) => {
    if (customerList && customerList.length) {
      const customer = customerList.find((x) => x.id == id);
      if (customer) return customer;
    }
    const doc2 = await getDoc(doc(db, collectionName + '/' + id));
    return doc2.data();
  };
  const setClients = async (id, data, errorMsg) => {
    return api
      .post(`customer/updateCustomerSession?id=${parseInt(id)}`, data)
      .then(async (result) => {
        if (
          result !== undefined &&
          (result.data.error === null || result.data.error === undefined)
        ) {
          return result.data;
        } else {
          if (errorMsg !== undefined) errorMsg(result.data.error.message);
        }
      })
      .catch((err) => {
        if (errorMsg !== undefined)
          errorMsg(
            'Sistemsel bir hata var. Lütfen yetkiliye başvurun.İletişim: feritzcan93@gmail.com',
            'danger'
          );
      });
  };
  const getCustomerNames = async (id, errorMsg) => {
    return api
      .get(`Customer/names`)
      .then(async (result) => {
        if (
          result !== undefined &&
          (result.data.error === null || result.data.error === undefined)
        ) {
          setCustomerNames(result.data);
          return result.data;
        } else {
          if (errorMsg !== undefined) errorMsg(result.data.error.message);
        }
      })
      .catch((err) => {
        if (errorMsg !== undefined)
          errorMsg(
            'Sistemsel bir hata var. Lütfen yetkiliye başvurun.İletişim: feritzcan93@gmail.com',
            'danger'
          );
      });
  };

  const addCustomer = async (data, errorMsg) => {
    const id = uuidv4();
    data.id = id;
    const out = { ...data };
    out.plannedTravelDate = fDate(out.plannedTravelDate);
    if (out.appointmentDate) out.appointmentDate = fDateTime(out.appointmentDate);
    await setDoc(doc(db, collectionName + '/' + id), out);
    return data;
  };

  const deleteCustomer = async (id, errorMsg) => {
    return api

      .delete('customer?customerId=' + id)
      .then(() => {
        let customers = customerList.filter((customer) => customer.id !== id);
        setCustomers(customers);
      })
      .catch((err) => {
        if (errorMsg !== undefined)
          errorMsg(
            'Sistemsel bir hata var. Lütfen yetkiliye başvurun.İletişim: feritzcan93@gmail.com',
            'danger'
          );
      });
  };

  return {
    getCustomers,
    addCustomer,
    deleteCustomer,
    getCustomer,
    getCustomerNames,
    setClients,
  };
}
