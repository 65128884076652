// scrollbar
import 'simplebar-react/dist/simplebar.min.css';
// image
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Fragment, useContext, useEffect } from 'react';
import { ref, onValue, getDatabase, get } from 'firebase/database';
import firebase_app from 'src/firebase-app';

// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// ----------------------------------------------------------------------
// routes
import Router from 'src/routes/sections';
import ConfigService from './api/ConfigService';
import { GlobalContext } from './context/GlobalProvider';
import { LoadingScreen } from './components/loading-screen';

// ----------------------------------------------------------------------

export default function AppMain() {
  const { getConfigs, getSearchConfigs, getFilters } = ConfigService();
  const { configs, setSearchConfigs, filters } = useContext(GlobalContext);

  useEffect(() => {
    getConfigs();
    getSearchConfigs((s) => {});
  }, []);
  useScrollToTop();
  if (configs === null || filters === null) return <LoadingScreen />;
  return (
    <Fragment>
      <Router />
    </Fragment>
  );
}
