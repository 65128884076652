import { Helmet } from 'react-helmet-async';
// sections
import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext } from 'react';
import { GlobalContext } from 'src/context/GlobalProvider';
import MainContainer from '../mainContainer';
import AvailableDatesView from './views/AvailableDatesView';
import TrackingDatesView from './views/TrackingDatesView';
import { LoadingScreen } from 'src/components/loading-screen';
import CountryDatesFilters from '../dashboard/views/AppointmentDates/CountryDatesFilters';
import UserTableFiltersResult from '../customer/list/user-table-filters-result';
import { applyFilters } from '../dashboard/views/AppointmentDates/filterHooks';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function CountryTrackerPage(props) {
  const { countryAppointmentData, searchConfigs, filters, configs } = useContext(GlobalContext);
  let index = countryAppointmentData.findIndex(
    (x) => x.name.toLowerCase() == props.country.toLowerCase()
  );
  if (searchConfigs === null || filters === null) return <LoadingScreen />;
  const countryConfigs = searchConfigs.filter((x) => x.countryId == props.country.id);
  const dataFiltered = applyFilters({
    inputData: countryConfigs,
    filters,
    configs,
    skipCountry: true,
  });
  return (
    <>
      <Helmet>
        <title> {props.country.name}</title>
      </Helmet>

      <Stack direction={'row'}>
        <Iconify
          icon={'flagpack:' + props.country.code?.toLowerCase()}
          sx={{ borderRadius: 0.65, width: 40, mr: 1, height: '100%' }}
        />
        <Typography variant="h4">{props.country.name}</Typography>
      </Stack>

      <MainContainer>
        <CountryDatesFilters skipCountry={true} />
        <Grid flexDirection={'column'} display={'flex'}>
          <AvailableDatesView
            country={props.country}
            searchConfigs={dataFiltered}
            countryData={countryAppointmentData[index]}
          />
          {/* <TrackingDatesView
            country={props.country}
            searchConfigs={countryConfigs}
            countryData={countryAppointmentData[index]}
          /> */}
        </Grid>
      </MainContainer>
    </>
  );
}
