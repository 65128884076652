import PropTypes from 'prop-types';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
// theme
import { bgGradient } from 'src/theme/css';
import { GlobalContext } from 'src/context/GlobalProvider';
import { useContext } from 'react';
import { Box, Card, Chip, ListItemText } from '@mui/material';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function AppWelcome({ title, description, action, img, trackedConfig, ...other }) {
  const theme = useTheme();
  const { configs } = useContext(GlobalContext);
  let country, visaCategory, center;
  if (trackedConfig) {
    country = configs.countries.find((x) => x.id == trackedConfig.countryId);
    visaCategory = configs.visaCategories.find((x) => x.id == trackedConfig.visaCategoryId);
    center = configs.centers.find((x) => x.id == trackedConfig.cityCenterId);
  }

  return (
    <Stack
      position={'relative'}
      flexDirection={{ xs: 'column', md: 'row' }}
      sx={{
        ...bgGradient({
          direction: '135deg',
          startColor: alpha(theme.palette.primary.light, 0.2),
          endColor: alpha(theme.palette.primary.main, 0.2),
        }),
        height: { md: 1 },
        borderRadius: 2,
        position: 'relative',
        color: 'primary.darker',
        backgroundColor: 'common.white',
      }}
      {...other}
    >
      {img && (
        <Stack
          display={{ md: 'none' }}
          position={'absolute'}
          component="span"
          justifyContent="flex-end"
          sx={{
            left: -30,
            top: -30,
            p: { xs: 2, md: 3 },
            maxWidth: { xs: 150, md: 0 },
            mx: 'auto',
          }}
        >
          {img}
        </Stack>
      )}
      <Stack
        flexGrow={1}
        justifyContent="center"
        alignItems={{ xs: 'center', md: 'flex-start' }}
        sx={{
          p: {
            xs: theme.spacing(5, 3, 0, 3),
            md: theme.spacing(5),
          },
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography variant="h4" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
          {title}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            opacity: 0.8,
            maxWidth: 460,
            mb: { xs: 3, xl: 5 },
          }}
        >
          {description}
        </Typography>

        {trackedConfig && (
          <Stack
            sx={{
              borderRadius: 1,
              padding: 1,
              marginBottom: 1,
              backgroundColor: 'rgba(145, 158, 171, 0.24)',
            }}
          >
            <Stack sx={{ alignItems: center }} direction={'row'}>
              <Iconify
                icon={GetCountryFlag(country)}
                sx={{ borderRadius: 0.65, width: 25, mr: 1, height: '100%' }}
              />
              <Stack sx={{ justifyContent: 'center', alignItems: 'center' }} direction={'row'}>
                {' '}
                <Typography variant="h6">{country.name}</Typography>
                <Typography sx={{ marginLeft: 2 }} variant="body3">
                  {center.name}
                </Typography>
              </Stack>
            </Stack>
            <Typography variant="subtitle2" color={'rgba(145, 158, 171, 0.8)'}>
              {visaCategory.category + ' / ' + visaCategory.subCategory}
            </Typography>
          </Stack>
        )}
        {action && action}
      </Stack>

      {img && (
        <Stack
          component="span"
          justifyContent="center"
          sx={{
            p: { xs: 2, md: 3 },
            maxWidth: { xs: 0, md: 360 },
            mx: 'auto',
          }}
        >
          {img}
        </Stack>
      )}
    </Stack>
  );
}
function GetCountryFlag(country) {
  return 'flagpack:' + country.code?.toLowerCase();
}
AppWelcome.propTypes = {
  action: PropTypes.node,
  description: PropTypes.string,
  img: PropTypes.node,
  title: PropTypes.string,
};
