import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo, useContext, useState } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { GlobalContext } from 'src/context/GlobalProvider';
import AuthService from '../../../api/AuthService';
import firebase_app from 'src/firebase-app';
import { getAuth } from 'firebase/auth';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: false,
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [isLoading, setLoading] = useState(true);
  let { setUserData, userData } = useContext(GlobalContext);
  const auth = getAuth(firebase_app);
  const authService = AuthService();
  const setData = async (user) => {
    if (!user) {
      setUserData(null);
      setLoading(false);
    }
    if (user != null) {
      let userData = authService.getUserData(user).then((data) => {
        setUserData(data);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setData(user);
      } else {
        authService.anonymousLogin();
      }
    });
    return unsubscribe; // This will let it clean up when the ArtistScreen unmounts
  }, []);

  // LOGIN
  const login = async (email, password) => {
    const data = {
      username: email,
      password,
    };
    await authService.loginUser(data);
  };

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    authService.logout();
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = userData != undefined && userData != null;
  const loading = isLoading || !userData ? true : false;
  const memoizedValue = {
    user: userData,
    method: 'jwt',
    loading: loading,
    authenticated: checkAuthenticated === true,
    unauthenticated: checkAuthenticated !== true,
    //
    login,
    register,
    logout,
  };
  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
