import { lazy, Suspense, useContext } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import CountryDashboardPage from 'src/pages/countryTracker/dashboard/CountryDashboardPage';
import { GlobalContext } from 'src/context/GlobalProvider';

// ----------------------------------------------------------------------

const CountryTrackerPage = lazy(() => import('src/pages/countryTracker/CountryTrackerPage'));
const NotificationSettingsPage = lazy(() =>
  import('src/pages/countryTracker/notificationSettings/NotificationSettingsPage')
);

// ----------------------------------------------------------------------
export const getCountryTrackerRoutes = () => {
  const { configs } = useContext(GlobalContext);

  let countryPaths = [];
  if (configs && configs.countries) {
    countryPaths = configs.countries.map((x) => {
      return { path: x.name, element: <CountryTrackerPage country={x} /> };
    });
  } else {
  }

  return [
    {
      path: 'randevuTakip',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { element: <CountryDashboardPage />, index: true },
        { path: 'genel', element: <CountryDashboardPage country={{ name: 'Genel' }} /> },
        { path: 'bildirim', element: <NotificationSettingsPage /> },
        ...countryPaths,
      ],
    },
  ];
};

export const countryTrackerRoutes = [
  {
    path: 'randevuTakip',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <CountryDashboardPage />, index: true },
      { path: 'genel', element: <CountryDashboardPage /> },
      { path: 'ispanya', element: <CountryTrackerPage country="ispanya" /> },
      { path: 'almanya', element: <CountryTrackerPage country="almanya" /> },
      { path: 'yunanistan', element: <CountryTrackerPage country="yunanistan" /> },
      { path: 'italya', element: <CountryTrackerPage country="italya" /> },
      { path: 'hollanda', element: <CountryTrackerPage country="hollanda" /> },
      { path: 'ayarlar', element: <NotificationSettingsPage /> },
    ],
  },
];
