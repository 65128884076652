import { createContext } from 'react';
import { Redirect } from 'react-router-dom';

const defaultBehaviour = {
  isAllowedTo: () => Promise.resolve(false),
};
import { getAuth } from 'firebase/auth';
import firebase_app from 'src/firebase-app';

// Get the Auth object
const app = firebase_app;
const auth = getAuth();
const PermissionContext = createContext(defaultBehaviour);

export default PermissionContext;

export const TOKEN_KEY = 'accessToken';

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
  sessionStorage.setItem(TOKEN_KEY, token);
};
export const setOrganisationId = (organisationId) => {
  localStorage.setItem('OrganisationId', organisationId);
  sessionStorage.setItem('OrganisationId', organisationId);
};
