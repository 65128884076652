import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';
import firebase_app from 'src/firebase-app';
import { getAuth } from 'firebase/auth';
import { isAuthenticatedUser } from '../hooks/auth-hooks';

// ----------------------------------------------------------------------

export default function NonAuthGuard({ children }) {
  const searchParams = useSearchParams();
  let authenticated = isAuthenticatedUser();
  const router = useRouter();

  const returnTo = searchParams.get('returnTo') || paths.countryTracking.countryDashboard;

  const check = useCallback(() => {
    if (authenticated) {
      router.replace(returnTo);
    }
  }, [authenticated, returnTo, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}

NonAuthGuard.propTypes = {
  children: PropTypes.node,
};
