import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

// components
import Iconify from 'src/components/iconify';
import { GlobalContext } from 'src/context/GlobalProvider';
import { useContext } from 'react';
import { defaultFilters, isFiltersDefault, normalizeFilters } from './filterHooks';

// ----------------------------------------------------------------------

export default function UserTableFiltersResult({ onFilters, results, ...other }) {
  const { configs, setFilters, filters } = useContext(GlobalContext);
  if (filters == null) return <></>;
  let isDefault = isFiltersDefault();
  if (isDefault) return <></>;
  const handleFilters = (name, value) => {
    let f = { ...filters };
    f[name] = value;
    let filters2 = normalizeFilters(configs, f);
    localStorage.setItem('dateFilters', JSON.stringify(filters2));
    setFilters(filters2);
    if (onFilters) onFilters();
  };
  const handleRemoveStatus = () => {
    handleFilters('status', 'all');
  };

  const countries = configs.countries;

  const onResetFilters = () => {
    localStorage.removeItem('dateFilters');
    setFilters(defaultFilters);
  };
  const handleRemoveRole = (inputValue) => {
    const newValue = filters.country.filter((item) => item !== inputValue);
    handleFilters('country', newValue);
  };
  const handleRemoveCenter = (inputValue) => {
    const newValue = filters.center.filter((item) => item !== inputValue);
    handleFilters('center', newValue);
  };
  const handleRemovePayment = (inputValue) => {
    const newValue = filters.payment.filter((item) => item !== inputValue);
    handleFilters('payment', newValue);
  };
  return (
    <Stack {...other}>
      <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center">
        {filters.status !== 'all' && (
          <Block label="Durum:">
            <Chip
              size="small"
              label={configs.customerStatuses.find((x) => x.id == filters.status).name}
              onDelete={handleRemoveStatus}
            />
          </Block>
        )}

        {!!filters.country.length && (
          <Block label="Ülke:">
            {filters.country
              .filter((value) => countries.find((y) => y.id === value) !== undefined)
              .map((item) => (
                <Chip
                  key={item}
                  label={countries.find((y) => y.id === item).name}
                  size="small"
                  onDelete={() => handleRemoveRole(item)}
                />
              ))}
          </Block>
        )}
        {!!filters.center.length && (
          <Block label="Şehir:">
            {filters.center.map((item) => (
              <Chip
                key={item}
                label={item}
                size="small"
                onDelete={() => handleRemoveCenter(item)}
              />
            ))}
          </Block>
        )}
        {!!filters.payment.length && (
          <Block label="Randevu durumu:">
            {filters.payment.map((item) => (
              <Chip
                key={item}
                label={item}
                size="small"
                onDelete={() => handleRemovePayment(item)}
              />
            ))}
          </Block>
        )}
        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          Filtreleri temizle
        </Button>
      </Stack>
      <Box sx={{ typography: 'body2', marginLeft: 1 }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          sonuç bulundu
        </Box>
      </Box>
    </Stack>
  );
}

UserTableFiltersResult.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  onResetFilters: PropTypes.func,
  results: PropTypes.number,
};

// ----------------------------------------------------------------------

function Block({ label, children, sx, ...other }) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}

Block.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  sx: PropTypes.object,
};
