import firebase_app from 'src/firebase-app';
import { getAuth } from 'firebase/auth';
import { useContext } from 'react';
import { GlobalContext } from 'src/context/GlobalProvider';
// ----------------------------------------------------------------------

export const isAuthenticatedUser = () => {
  const auth = getAuth(firebase_app);
  let authenticated = auth?.currentUser?.isAnonymous === false;
  return authenticated;
};
export const getFirebaseUser = () => {
  const auth = getAuth(firebase_app);
  return auth.currentUser;
};

export const isAdminContext = () => {
  let { userData } = useContext(GlobalContext);
  if (!userData) return false;
  return userData.isAdmin === true;
};
export const getUserData = () => {
  let userData = useContext(GlobalContext);
  return userData;
};
