import isEqual from 'lodash/isEqual';
import { useState, useCallback, useContext } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _userList, _roles, USER_STATUS_OPTIONS } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
//
import UserTableRow from './user-table-row';
import UserTableToolbar from './user-table-toolbar';
import UserTableFiltersResult from './user-table-filters-result';
import MainContainer from 'src/pages/mainContainer';
import { LoadingScreen } from 'src/components/loading-screen';
import { GlobalContext } from 'src/context/GlobalProvider';
import UserQuickEditForm from './components/UserQuickEditForm';

const _paymentOptions = ['Ödeme Alındı.', 'Ödeme Alınmadı'];
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'İsim', width: 140 },
  { id: 'phoneNumber', label: 'İletişim', width: 140 },
  { id: 'company', label: 'Ülke', width: 100 },
  { id: 'company', label: 'Ödeme', width: 100 },
  { id: 'role', label: 'Gidiş Tarihi', width: 150 },
  { id: 'roles', label: 'Randevu Tarihi', width: 150 },
  { id: 'status', label: 'Durumu', width: 80 },
  { id: '', width: 88 },
];

const defaultFilters = {
  name: '',
  role: [],
  payment: [],
  status: 'all',
};

// ----------------------------------------------------------------------

export default function UserListView({ customerList }) {
  const { configs } = useContext(GlobalContext);
  if (configs == undefined || customerList == undefined) return <LoadingScreen />;
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(_userList);
  const [quickEditUser, setQuickEditUser] = useState(null);
  const [filters, setFilters] = useState(defaultFilters);

  const configStatuses = configs.customerStatuses.map((x) => {
    return { value: x.id, label: x.name };
  });
  const dataFiltered = applyFilter({
    inputData: customerList,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    configs,
  });
  const STATUS_OPTIONS = [{ value: 'all', label: 'Hepsi' }, ...configStatuses];

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);
  if (customerList == null || customerList == undefined) return <LoadingScreen />;
  return (
    <MainContainer>
      <UserQuickEditForm
        currentUser={quickEditUser}
        open={quickEditUser != null}
        onClose={() => {
          setQuickEditUser(null);
        }}
      />
      <Card>
        <Tabs
          value={filters.status}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab, index) => (
            <Tab
              key={tab.value}
              iconPosition="end"
              value={tab.value}
              label={tab.label}
              icon={
                <Label
                  colo
                  variant={
                    ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                  }
                  color={
                    (index == 1 && 'success') ||
                    (index == 2 && 'warning') ||
                    (index == 3 && 'info') ||
                    (index == 5 && 'primary') ||
                    (index == 6 && 'secondary') ||
                    (index == 4 && 'error') ||
                    'default'
                  }
                >
                  {tab.value === 'all' && customerList.length}
                  {tab.value !== 'all' &&
                    customerList.filter((user) => user.status === tab.value).length}
                </Label>
              }
            />
          ))}
        </Tabs>

        <UserTableToolbar
          filters={filters}
          onFilters={handleFilters}
          //
          paymentOptions={_paymentOptions}
          roleOptions={configs.countries
            .map((x) => x.id)
            .filter((value, index, array) => array.indexOf(value) === index)}
        />

        {canReset && (
          <UserTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered.length}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer>
          <TableSelectedAction
            numSelected={table.selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            <Table>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
              />

              <TableBody>
                {dataFiltered
                  .slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  )
                  .map((row, index) => (
                    <UserTableRow
                      quickEdit={() => setQuickEditUser(row)}
                      index={index}
                      customer={row}
                      key={row.id}
                      row={row}
                      selected={table.selected.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                    />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </MainContainer>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters, configs }) {
  const { name, status, role, payment } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter((user) => user.status === status);
  }

  if (role.length) {
    const countries = role.map((x) => configs.countries.find((x) => x.id == x)?.id);

    inputData = inputData.filter((user) => countries.includes(user.country));
  }
  if (payment.length) {
    if (payment.length == 1) {
      if (payment[0] == _paymentOptions[0]) {
        inputData = inputData.filter((user) => user.paymentDone > 0);
      }
      if (payment[0] == _paymentOptions[1]) {
        inputData = inputData.filter(
          (user) =>
            user.paymentDone == null || user.paymentDone == undefined || user.paymentDone == 0
        );
      }
    }
  }
  return inputData;
}
