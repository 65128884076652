// @mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
// hooks
// _mock
import { _appInstalled } from 'src/_mock';
// components
import { useSettingsContext } from 'src/components/settings';
// assets
import { Button, Card, Divider, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from 'src/context/GlobalProvider';
import MainContainer from 'src/pages/mainContainer';
import CountryDatesWidget from 'src/pages/dashboard/views/CountryDatesWidget';
import SeoIllustration from 'src/assets/illustrations/seo-illustration';
import AppWelcome from './app-welcome';
import { getFirebaseUser, isAdminContext, isAuthenticatedUser } from 'src/auth/hooks/auth-hooks';
import NonAuthGuard from 'src/auth/guard/NonAuthGuard';
import { useNavigate } from 'react-router';
import { AuthGuard } from 'src/auth/guard';
import CountryDateRow from 'src/pages/dashboard/views/AppointmentDates/CountryDateRow';
import AuthService from 'src/api/AuthService';
// ----------------------------------------------------------------------

export default function DashboardView() {
  const { userData, dashboardData, configs, searchConfigs } = useContext(GlobalContext);
  const theme = useTheme();
  const [trackedConfig, setTrackedConfig] = useState(null);
  const navigate = useNavigate();

  let authService = AuthService();
  let isAuthenticated = isAuthenticatedUser();

  let firebaseUser = getFirebaseUser();
  let isAdmin = isAdminContext();
  let username = firebaseUser && firebaseUser.email !== null ? firebaseUser.email : '';

  useEffect(() => {
    let d = null;
    if (searchConfigs && searchConfigs.length > 0 && userData.remoteData?.trackedConfig) {
      d = searchConfigs.find((x) => x.Id == userData.remoteData?.trackedConfig);
    }
    setTrackedConfig(d);
  }, [userData, searchConfigs]);

  return (
    <MainContainer>
      <Grid container spacing={5}>
        <Grid spacing={5} xs={12} md={12} lg={12}>
          <Grid xs={12} md={8}>
            {isAuthenticated === false && (
              <AppWelcome
                title={`Hoşgeldin 👋 \n ` + username}
                description="Şu anda hiç bir randevuyu takip etmiyorsun. Yer açıldığında email ile bildirim almak için giriş yap."
                img={<SeoIllustration />}
                action={
                  <Button onClick={() => navigate(`/girisYap`)} variant="contained" color="primary">
                    Giriş yap
                  </Button>
                }
              />
            )}
            {isAuthenticated === true && !userData.remoteData?.trackedConfig && (
              <AppWelcome
                title={`Hoşgeldin 👋 \n ` + username}
                description="Şu anda hiç bir randevuyu takip etmiyorsun. Mail bildirimi almayacaksın."
                img={<SeoIllustration />}
                action={
                  <Button
                    disabled={true}
                    onClick={() => navigate(`/girisYap`)}
                    variant="contained"
                    color="primary"
                  >
                    Bir kategoride takip et butonuna basarak bildirim alabilirsin.
                  </Button>
                }
              />
            )}
            {isAuthenticated === true && trackedConfig && (
              <AppWelcome
                trackedConfig={trackedConfig}
                title={`Hoşgeldin 👋 \n ` + username}
                description="Aşağıdaki kategoriyi takip ediyorsun. Yer açıldığında bildirim alacaksın."
                img={<SeoIllustration />}
                action={
                  <Button
                    onClick={() => authService.unfollowDate()}
                    variant="contained"
                    color="error"
                  >
                    Takibi bırak.
                  </Button>
                }
              />
            )}
          </Grid>

          <Grid xs={6} md={6} lg={6}>
            <CountryDatesWidget
              trackedConfig={trackedConfig}
              configs={configs}
              countryAppointmentData={searchConfigs}
              list={_appInstalled}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 3, mb: 55 }}></Divider>
      </Grid>
    </MainContainer>
  );
}
