import api from './api';
import { useContext, useReducer } from 'react';
import { GlobalContext } from '../context/GlobalProvider';
const pako = require('pako');

import firebase_app from 'src/firebase-app';
import { getFirestore, doc, setDoc, getDocs, collection, getDoc } from 'firebase/firestore';
import { ref, onValue, getDatabase, get } from 'firebase/database';
import {
  defaultFilters,
  normalizeFilters,
} from 'src/pages/dashboard/views/AppointmentDates/filterHooks';

export default function ConfigService() {
  let { setConfigs, setFilters, configs, setSearchConfigs } = useContext(GlobalContext);

  const getConfigs = async (data, errorMsg) => {
    const db = getFirestore(firebase_app);
    const collectionRef = collection(db, 'Configurations');
    const snapshot = await getDocs(collectionRef);
    const configObject = snapshot.docs.reduce((output, doc) => {
      return { ...output, ...doc.data() };
    }, {});
    if (configObject.visaCategories) {
      configObject.visaCategories = configObject.visaCategories.filter((x) => x.isActive === true);
      configObject.countries = configObject.countries.filter((x) => x.isActive === true);
      configObject.centers = configObject.centers.filter((x) => x.isActive === true);
    }

    setConfigs(configObject);
    calculateFilters(configObject);

    return configObject;
  };
  const calculateFilters = (configs) => {
    const cachedFilters =
      localStorage.getItem('dateFilters') != null
        ? JSON.parse(localStorage.getItem('dateFilters'))
        : defaultFilters;
    let filters = normalizeFilters(configs, cachedFilters);
    setFilters(filters);
  };

  const getSearchConfigs = async (func) => {
    const db = getDatabase(firebase_app, 'https://vizedefteri-f9051-default-rtdb.firebaseio.com');
    const dbRef = ref(db, 'SearchConfigurations');

    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const data = Object.entries(snapshot.val()).map((e) => {
        let d = { ...e[1] };
        d.updateMinutes = getMinuteDifference(d);
        d.status = getSearchConfigStatus(d);
        return { ...d, Id: e[0] };
      });
      setSearchConfigs(data);
    } else {
    }
    return onValue(dbRef, (snapshot) => {
      func(snapshot);
    });
  };
  const getMinuteDifference = (searchConfig) => {
    let date1 = new Date();
    let date2 = new Date(searchConfig.updateDate);
    const diffInMilliseconds = Math.abs(date2 - date1);
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    return diffInMinutes;
  };
  const getSearchConfigStatus = (searchConfig) => {
    let status = 2;
    if (searchConfig.updateMinutes > 10) status = 2;
    else if (searchConfig.appointmentDate != null && searchConfig.appointmentDate != undefined)
      status = 0;
    else status = 1;
    return status;
  };

  return {
    getConfigs,
    getSearchConfigs,
  };
}
