import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import UserQuickEditForm from './user-quick-edit-form';
import { _mock } from 'src/_mock';
import { random } from 'lodash';
import { useContext } from 'react';
import { GlobalContext } from 'src/context/GlobalProvider';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

export default function UserTableRow({
  index,
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  customer,
  quickEdit,
}) {
  const status = 'active';
  const avatarUrl = `${process.env.REACT_APP_ASSETS_API}/assets/images/avatar/avatar_${
    index + 1
  }.jpg`;
  const confirm = useBoolean();
  const { configs } = useContext(GlobalContext);
  const configStatuses = configs.customerStatuses.map((x) => {
    return { value: x.id, label: x.name };
  });
  const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...configStatuses];
  const statusIndex = STATUS_OPTIONS.findIndex((x) => x.value == customer.status);
  const statusData = STATUS_OPTIONS.find((x) => x.value == customer.status);
  const navigate = useNavigate();

  const popover = usePopover();
  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={customer.name} src={avatarUrl} sx={{ mr: 2 }} />

          <ListItemText
            primary={customer.name}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <ListItemText
            primary={customer.phone}
            secondary={customer.email}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {configs.countries.find((x) => x.id == customer.country)?.name}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {customer.paymentDone ? customer.paymentDone : 'Alınmadı'}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{customer.plannedTravelDate}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {customer.appointmentDate ? customer.appointmentDate : 'YOK'}
        </TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={
              (statusIndex == 1 && 'success') ||
              (statusIndex == 2 && 'warning') ||
              (statusIndex == 3 && 'info') ||
              (statusIndex == 5 && 'primary') ||
              (statusIndex == 6 && 'secondary') ||
              (statusIndex == 4 && 'error') ||
              'default'
            }
          >
            {statusData.label}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Müşteri detayına git" placement="top" arrow>
            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={() => navigate(`/musteri/${row.id}`)}
            >
              <Iconify icon="lucide:view" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Hızlı Düzenle" placement="top" arrow>
            <IconButton color={'default'} onClick={quickEdit}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>

          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Sil
        </MenuItem>

        <MenuItem
          onClick={() => {
            quickEdit();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Düzenle
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
