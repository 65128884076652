import isEqual from 'lodash/isEqual';
import { useState, useCallback, useContext } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _userList, _roles, USER_STATUS_OPTIONS } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
//
import CountryDateRow from './CountryDateRow';
import CountryDatesFilters from './CountryDatesFilters';
import UserTableFiltersResult from './user-table-filters-result';
import MainContainer from 'src/pages/mainContainer';
import { LoadingScreen } from 'src/components/loading-screen';
import { GlobalContext } from 'src/context/GlobalProvider';
import {
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import {
  applyFilters,
  getAppointmentFilters,
  isFiltersDefault,
  paymentOptionsFilters,
  resetFilters,
  setAppointmentFilters,
} from './filterHooks';
import { reset } from 'numeral';
import { de } from 'date-fns/locale';
import { isAuthenticated } from 'src/context/AuthContext';
import { isAuthenticatedUser } from 'src/auth/hooks/auth-hooks';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    id: 'button',
    label: '',
    width: 30,
  },
  {
    id: 'countryName',
    label: 'Ülke',
    width: 110,
  },
  {
    id: 'city',
    label: 'Şehir',
    width: 110,
  },
  { id: 'updateDate', label: 'Güncellenme Zamanı', width: 125 },
  { id: 'appointmentDate', label: 'Randevu Tarihi', width: 125 },
  { id: 'status', label: 'Randevu Türü', width: 500 },
];

const defaultFilters = {
  name: '',
  country: [],
  payment: [],
  center: [],
  status: 'all',
  sorting: '',
};

// ----------------------------------------------------------------------

export default function CountryDatesListView({ searchConfigs, trackedConfig }) {
  const dialog = useBoolean(false);
  const navigate = useNavigate();
  const { configs, userData, filters } = useContext(GlobalContext);
  if (configs == undefined || filters == null || searchConfigs == undefined)
    return <LoadingScreen />;
  const table = useTable({ defaultRowsPerPage: 10 });

  const isAuth = isAuthenticatedUser();
  const confirm = useBoolean();

  const dataFiltered = applyFilters({
    inputData: searchConfigs,
    filters,
    configs,
  });

  const handleFilters = (name, value) => {
    table.onResetPage();
  };

  const warningDialog = () => {
    if (isAuth)
      return (
        <Dialog open={dialog.value} onClose={dialog.onFalse}>
          <DialogTitle>{`Birden fazla randevuyu takip edemessin.`}</DialogTitle>

          <DialogContent sx={{ color: 'text.secondary' }}>
            Bu özellik sadece danışman hesaplara açık. Bu ve daha bir çok özelliğe erişim sağlamak
            için bize ulaş.
          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={dialog.onFalse} autoFocus>
              Tamam
            </Button>
          </DialogActions>
        </Dialog>
      );
    else
      return (
        <Dialog open={dialog.value} onClose={dialog.onFalse}>
          <DialogTitle>{`Lütfen giriş yap`}</DialogTitle>

          <DialogContent sx={{ color: 'text.secondary' }}>
            Yer açıldığında mail ile bildirim almak için lütfen giriş yap!
          </DialogContent>

          <DialogActions>
            <Button color="error" variant="contained" onClick={() => navigate(`/kaydol`)} autoFocus>
              Kaydol
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate(`/girisYap`)}
              autoFocus
            >
              Giriş yap
            </Button>
          </DialogActions>
        </Dialog>
      );
  };

  return (
    <>
      {warningDialog()}
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
      >
        <CountryDatesFilters onFilters={handleFilters} />
        <UserTableFiltersResult
          onFilters={handleFilters}
          //
          //
          results={dataFiltered.length}
          sx={{ p: 2.5, pt: 0 }}
        />
      </Stack>
      <TableContainer>
        <TableSelectedAction
          numSelected={table.selected.length}
          rowCount={searchConfigs.length}
          action={
            <Tooltip title="Delete">
              <IconButton color="primary" onClick={confirm.onTrue}>
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Tooltip>
          }
        />

        <Scrollbar>
          <Table>
            <TableHeadCustom
              sx={{ display: 'table-header-group' }}
              selecteable={false}
              headLabel={TABLE_HEAD}
              rowCount={searchConfigs.length}
              numSelected={table.selected.length}
            />

            <TableBody>
              {dataFiltered
                .slice(
                  table.page * table.rowsPerPage,
                  table.page * table.rowsPerPage + table.rowsPerPage
                )
                .map((row, index) => (
                  <CountryDateRow
                    isAuthenticated={isAuth}
                    isTracked={trackedConfig && trackedConfig.Id === row.Id ? true : false}
                    headLabel={TABLE_HEAD}
                    index={index}
                    customer={row}
                    key={row.Id}
                    searchConfig={row}
                    canTrack={!trackedConfig}
                    row={row}
                    openDialog={dialog.onTrue}
                    selected={table.selected.includes(row.id)}
                    onSelectRow={() => table.onSelectRow(row.id)}
                  />
                ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={dataFiltered.length}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </>
  );
}
