import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { clientRoutes } from './clientRoutes';
import { getCountryTrackerRoutes } from './countryTrackerRoutes';
import { customerRoutes } from './customerRoutes';
import { dashboardRoutes } from './dashboard';
import { settingsRoutes } from './settingsRoutes';
import CountryDashboardPage from 'src/pages/countryTracker/dashboard/CountryDashboardPage';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard/layout';
import { LoadingScreen } from 'src/components/loading-screen';
import { Suspense, useContext } from 'react';
import { GlobalContext } from 'src/context/GlobalProvider';
import CountryTrackerPage from 'src/pages/countryTracker/CountryTrackerPage';
import NotificationSettingsPage from 'src/pages/countryTracker/notificationSettings/NotificationSettingsPage';
import AboutPage from 'src/pages/about/about-us';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,
    ...customerRoutes,
    ...getCountryTrackerRoutes(),
    // Dashboard routes
    ...dashboardRoutes,
    ...clientRoutes,

    // Main routes
    ...mainRoutes,
    ...settingsRoutes,
    {
      path: 'kurumsal',
      element: <AboutPage></AboutPage>,
    },

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
