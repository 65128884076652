import AboutHero from '../about-hero';
import AboutWhat from '../about-what';
import AboutTeam from '../about-team';
import AboutVision from '../about-vision';
import AboutTestimonials from '../about-testimonials';
import { kurumsalHat, musteriYonetimiTexts, randevuBotu } from './aboutTexts';

// ----------------------------------------------------------------------

export default function AboutView() {
  return (
    <>
      <AboutHero />

      <AboutWhat />

      <AboutTestimonials testimontals={musteriYonetimiTexts} isDark={true} />
      <AboutTestimonials testimontals={kurumsalHat} />
      <AboutTestimonials testimontals={randevuBotu} isDark={true} />
    </>
  );
}
