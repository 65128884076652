export const kurumsalHat = {
  title: 'Kurumsal Sabit Hat',
  description:
    'Kurumsal sabit hat çözümümüz ile işletmenizin iletişim altyapısını güçlendirin ve müşteri ilişkilerini optimize edin. Kesintisiz ve etkili bir iletişim için ideal.',
  data: [
    {
      title: 'Kesintisiz İletişim',
      content:
        'Mesai saatleri dışında bile arayan müşterilere otomatik mesajlar göndererek, her zaman ulaşılabilir olun.',
    },
    {
      title: 'Etkileşim Artırıcı',
      content:
        'Arayıp kayıt açmayan müşterilere otomatik olarak hatırlatma mesajları gönderin ve daha fazla müşteri edinin.',
    },
    {
      title: 'Proaktif Yaklaşım',
      content:
        'Eksik dosyaları olan müşterilere otomatik hatırlatmalar göndererek, işlemleri hızlandırın.',
    },
    {
      title: 'Randevu Yönetimi',
      content:
        'Randevu tarihleri yaklaşan müşterilere hem sizin hem de onların haberdar olması için otomatik bildirimler gönderin.',
    },
    {
      title: 'Esnek Kullanım',
      content:
        'Aynı numarayı birden fazla danışman kullanabilir, böylece tüm takım aynı standartlarda hizmet verebilir.',
    },
  ],
};

export const musteriYonetimiTexts = {
  title: 'Müşteri Yönetimi',
  description:
    'Müşteri yönetimi sistemi ile müşteri kayıtları oluşturabilir, durum takibini daha rahat yapabilirsiniz. ',
  data: [
    {
      title: 'Dosya Yönetimi',
      content:
        'Müşteriler tarafından yüklenen dosyaları kolayca görüntüleyin, onaylayın veya geri bildirimde bulunun.',
    },
    {
      title: 'Başvuru Takibi',
      content:
        'Her müşterinin başvuru durumunu detaylı bir şekilde görüntüleyin. Hangi adımda olduklarını ve neye ihtiyaç duyduklarını takip edin.',
    },
    {
      title: 'Hızlı Erişim',
      content:
        'Müşteri bilgilerine ve dosyalarına hızlıca erişin. Verimli bir şekilde işlem yapın.',
    },
    {
      title: 'Proaktif Bildirimler',
      content:
        'Müşterilerinize ve danışmanlarınıza otomatik bildirimler gönderin. Herkesi güncel tutun.',
    },
    {
      title: 'Sanal Başvuru Sistemi',
      content:
        'Danışmanların müşterilere verdiği özel linkler üzerinden müşteriler tüm belgelerini yükleyebilir ve bilgilerini güvenli bir şekilde girer. E-posta ile belge gönderme ihtiyacını ortadan kaldırarak süreçleri daha verimli hale getirin.',
    },
  ],
};
export const randevuBotu = {
  title: 'Randevu botu',
  description: 'Randevu botu ile vize randevularını takip et. ',
  data: [
    {
      title: 'Sınırsız takip',
      content: 'Sistemimizdeki  ve eklenmekte olan tüm ülkeler için randevuları takip et.',
    },
    {
      title: 'Takım işi',
      content: 'Bildirim almak için istediğin kadar mail ekle.',
    },
    {
      title: 'Farklı kanallar',
      content: 'Sadece mail ile değil, whatsapp ya da telegram ile de bildirim al.',
    },
  ],
};
