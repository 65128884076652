import * as React from 'react';
// @mui
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';

// components
import { useTheme } from '@emotion/react';
import { Stack, colors } from '@mui/material';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSettingsContext } from 'src/components/settings';
import { bgGradient } from 'src/theme/css';
import { GlobalContext } from 'src/context/GlobalProvider';

// ----------------------------------------------------------------------
const visaTypes = [
  { value: 1, name: 'Turizm' },
  { value: 2, name: 'İş' },
  { value: 3, name: 'Diğer' },
  { value: 0, name: 'Bilinmeyen' },
];
export default function AvailableDatesView(props) {
  const settings = useSettingsContext();
  const [value, setValue] = React.useState(0);
  const [configsByType, setConfigsByType] = React.useState({});

  const { configs } = React.useContext(GlobalContext);
  const calculateConfigsByType = () => {
    let c = searchConfigs.reduce((output, value) => {
      let cat = configs.visaCategories.find((x) => x.id == value.visaCategoryId);
      if (output[cat.type]) output[cat.type].push(value);
      else output[cat.type] = [value];
      return output;
    }, {});

    for (const type of visaTypes) {
      if (c[type.value]) {
        setValue(type.value);
        break;
      }
    }
    setConfigsByType(c);
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { country, searchConfigs } = props;

  React.useEffect(() => {
    calculateConfigsByType();
  }, [searchConfigs]);

  if (country === undefined || searchConfigs === undefined) return <LoadingScreen />;
  return (
    <>
      <Typography color={colors.brown[400]} variant="h6">
        Vize türleri
      </Typography>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            {visaTypes
              .filter((type) => configsByType[type.value] && configsByType[type.value].length > 0)
              .map((popover, i) => {
                return <Tab value={popover.value} key={popover.name} label={popover.name} />;
              })}
          </Tabs>
        </Box>
        {visaTypes.map((type, i) => {
          return (
            <CustomTabPanel key={type.value} index={type.value} value={value}>
              <Stack spacing={1}>
                {configsByType[type.value] &&
                  configsByType[type.value]
                    .sort((a, b) => a.status - b.status)
                    .map((x) => {
                      return <DateRow configs={configs} key={x.Id} searchConfig={x} />;
                    })}
              </Stack>
              {/* {!popover.appointmentDate && (
                <DateRow
                  key={popover.officeName + 'i'}
                  updateMinutes={updateMinutes}
                  color="error"
                />
              )}
              {!popover.appointmentDate && (
                <DateRow
                  key={popover.officeName + 'i'}
                  updateMinutes={updateMinutes}
                  color="warning"
                />
              )} */}
            </CustomTabPanel>
          );
        })}
      </Box>
    </>
  );
}

function DateRow({ configs, date, title, total, icon, sx, searchConfig, ...other }) {
  let color =
    searchConfig.status === 0 ? 'primary' : searchConfig.status === 1 ? 'error' : 'warning';
  const theme = useTheme();
  let cat = configs.visaCategories.find((x) => x.id == searchConfig.visaCategoryId);
  let center = configs.centers.find((x) => x.id == searchConfig.cityCenterId);
  let updateMinutes = searchConfig.updateMinutes;
  return (
    <Stack
      component="span"
      sx={{
        ...bgGradient({
          direction: '135deg',
          startColor: alpha(theme.palette[color].light, 0.2),
          endColor: alpha(theme.palette[color].main, 0.2),
        }),
        py: 1,
        borderRadius: 2,
        textAlign: 'center',
        color: `${color}.darker`,
        backgroundColor: 'common.white',
        ...sx,
      }}
      {...other}
    >
      {icon && <Box sx={{ width: 64, height: 64, mb: 1 }}>{icon}</Box>}

      <Typography component={'span'} variant="h4">
        {center.name}
      </Typography>
      <Typography component={'span'} variant="h6">
        {searchConfig.status === 1
          ? 'Uygun Tarih Yok'
          : searchConfig.status === 2
          ? 'Güncellenmedi'
          : searchConfig.appointmentDate.split('T')[0]}
      </Typography>
      {cat.name}
      <Typography component={'span'} variant="subtitle2" sx={{ opacity: 0.64 }}>
        {searchConfig.status === 2
          ? 'Sistemden veri alınamadı. Lütfen kendiniz kontrol edin.  ' +
            updateMinutes +
            '  dakika önce güncellendi.'
          : updateMinutes + '  dakika önce güncellendi.'}
      </Typography>
    </Stack>
  );
}
