import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import AuthService from 'src/api/AuthService';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import UserQuickEditForm from './user-quick-edit-form';
import { _mock } from 'src/_mock';
import { random } from 'lodash';
import { useContext } from 'react';
import { GlobalContext } from 'src/context/GlobalProvider';
import { useNavigate } from 'react-router';
import { fDate, fToNow } from 'src/utils/format-time';
import { Stack } from '@mui/material';

// ----------------------------------------------------------------------

export default function CountryDateRow({
  index,
  row,
  headLabel,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  customer,
  quickEdit,
  searchConfig,
  isTracked,
  canTrack,
  openDialog,
  isAuthenticated,
}) {
  const { configs } = useContext(GlobalContext);

  let authService = AuthService();
  let country = configs.countries.find((x) => x.id == searchConfig.countryId);
  let visaCategory = configs.visaCategories.find((x) => x.id == searchConfig.visaCategoryId);
  let center = configs.centers.find((x) => x.id == searchConfig.cityCenterId);

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            width: headLabel[1].width,
            minWidth: headLabel[1].width,
            maxWidth: headLabel[1].width,
          }}
        >
          <Button
            onClick={() => {
              if (isAuthenticated && (canTrack || isTracked)) {
                isTracked ? authService.unfollowDate() : authService.followDate(searchConfig.Id);
              } else {
                openDialog();
              }
            }}
            size="small"
            color={isTracked ? 'error' : 'success'}
            variant="contained"
          >
            {isTracked ? 'Takibi bırak' : 'Takip et'}
          </Button>
        </TableCell>
        <TableCell
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: headLabel[0].width,
            minWidth: headLabel[0].width,
            maxWidth: headLabel[0].width,
          }}
        >
          <Stack direction={'row'}>
            <Iconify
              icon={GetCountryFlag(country)}
              sx={{ borderRadius: 0.65, width: 25, mr: 1, height: '100%' }}
            />
            <ListItemText
              primary={country.name}
              primaryTypographyProps={{ typography: 'body2', height: '100%' }}
            />
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: headLabel[0].width,
            minWidth: headLabel[0].width,
            maxWidth: headLabel[0].width,
          }}
        >
          {center.name}
        </TableCell>

        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            width: headLabel[1].width,
            minWidth: headLabel[1].width,
            maxWidth: headLabel[1].width,
          }}
        >
          {getMinuteDifference(searchConfig) > 120
            ? getMinuteDifference(searchConfig / 60) + ' saat önce.'
            : getMinuteDifference(searchConfig) + ' dakika önce.'}
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            width: headLabel[2].width,
            minWidth: headLabel[2].width,
            maxWidth: headLabel[2].width,
          }}
        >
          {getAppointmentDateView(searchConfig)}
        </TableCell>

        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            width: headLabel[3].width,
            minWidth: headLabel[3].width,
            maxWidth: headLabel[3].width,
          }}
        >
          <ListItemText
            primary={visaCategory.category}
            secondary={visaCategory.subCategory}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
}
const getAppointmentDateView = (config) => {
  let old = getMinuteDifference(config) > 10;
  if (old === true) {
    return (
      <>
        <Label style={{ fontSize: '1rem' }} color="warning">
          Güncellenmedi
        </Label>
      </>
    );
  } else if (config.appointmentDate !== null && config.appointmentDate !== undefined) {
    return (
      <>
        <Label style={{ fontSize: '1rem' }} color="primary">
          {fDate(config.appointmentDate)}
        </Label>
      </>
    );
  } else {
    return (
      <Label style={{ fontSize: '1rem' }} color="error">
        Randevu yok
      </Label>
    );
  }
};

function GetCountryFlag(country) {
  return 'flagpack:' + country.code?.toLowerCase();
}
const getMinuteDifference = (country) => {
  if (!country || !country.updateDate) return 100;
  let date1 = new Date();
  let date2 = new Date(country.updateDate);
  const diffInMilliseconds = Math.abs(date2 - date1);
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  return diffInMinutes;
};
CountryDateRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
