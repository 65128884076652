import { isEqual } from 'lodash';
import { useContext } from 'react';
import { GlobalContext } from 'src/context/GlobalProvider';

export const defaultFilters = {
  name: '',
  country: [],
  payment: [],
  center: [],
  status: 'all',
  sorting: '',
};
export const paymentOptionsFilters = ['Uygun randevu var.', 'Uygun randevu yok.'];

export const isFiltersDefault = () => {
  const { filters } = useContext(GlobalContext);
  return isEqual(filters, defaultFilters);
};

export const resetFilters = () => {
  const { setFilters, configs } = useContext(GlobalContext);
  setFilters(defaultFilters);
};

export const normalizeFilters = (configs, filters) => {
  filters.country = filters.country.filter(
    (value) => configs.countries.find((y) => y.id === value) !== undefined
  );
  let centerOptions = [...new Set(configs.centers.map((x) => x.name.split('-')[0]).sort())];

  filters.center = filters.center.filter((x) => centerOptions.includes(x));
  return filters;
};

export const applyFilters = ({ inputData, order, filters, configs, skipCountry }) => {
  const { name, status, country, payment, center } = filters;
  const countries = configs.countries;
  const stabilizedThis = inputData.map((el, index) => [el, index]);
  const comparor = (a, b) => {
    let d1 = a.updateMinutes;
    let d2 = b.updateMinutes;
    let updateSorting = d1 > d2 ? 1 : -1;

    if (updateSorting === 0 || (d1 < 10 && d2 < 10)) {
      let appointmentSort = 0;
      if (!a.appointmentDate && !b.appointmentDate) appointmentSort = 0;
      else if (a.appointmentDate && !b.appointmentDate) appointmentSort = -1;
      else if (!a.appointmentDate && b.appointmentDate) appointmentSort = 1;
      else if (a.appointmentDate && b.appointmentDate) {
        appointmentSort = new Date(a.appointmentDate) < new Date(b.appointmentDate) ? -1 : 1;
      }
      if (appointmentSort !== 0) {
        return appointmentSort;
      }
    }
    return updateSorting;
  };
  stabilizedThis.sort((a, b) => {
    const order = comparor(a[0], b[0]);
    return order;
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (country.length && skipCountry !== true) {
    inputData = inputData.filter((user) => country.includes(user.countryId));
  }
  if (center.length) {
    inputData = inputData.filter((user) => {
      let dataCenter = configs.centers.find((z) => z.id == user.cityCenterId);
      if (dataCenter === undefined) {
        dataCenter = 'Bilinmiyor';
      } else {
        dataCenter = dataCenter.name;
      }
      let index = center.find((x) => dataCenter.includes(x)) !== undefined;
      return index;
    });
  }
  if (payment.length) {
    if (payment.length == 1) {
      if (payment[0] == paymentOptionsFilters[0]) {
        inputData = inputData.filter(
          (user) => user.appointmentDate != null && user.appointmentDate != undefined
        );
      }
      if (payment[0] == paymentOptionsFilters[1]) {
        inputData = inputData.filter(
          (user) => user.appointmentDate == null || user.appointmentDate == undefined
        );
      }
    }
  }
  return inputData;
};
