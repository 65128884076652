import PropTypes from 'prop-types';
import { useCallback, useContext } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { GlobalContext } from 'src/context/GlobalProvider';
import { normalizeFilters, paymentOptionsFilters } from './filterHooks';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function CountryDatesFilters({ onFilters, skipCountry }) {
  const { configs, setFilters, filters } = useContext(GlobalContext);
  let centerOptions = [...new Set(configs.centers.map((x) => x.name.split('-')[0]).sort())];
  let roleOptions = configs.countries.map((x) => x.id);

  const countries = configs.countries;
  const handleFilters = (name, value) => {
    let f = { ...filters };
    f[name] = value;
    let filters2 = normalizeFilters(configs, f);
    localStorage.setItem('dateFilters', JSON.stringify(filters2));

    setFilters(filters2);
    if (onFilters) onFilters();
  };

  const handleFilterRole = (event) => {
    let countryIds =
      event.target.value === 'string' ? event.target.value.split(',') : event.target.value;

    handleFilters(
      'country',
      countryIds.filter((x) => countries.findIndex((y) => y.id === x) >= 0)
    );
  };
  const handleFilterCity = useCallback(
    (event) => {
      let countryIds =
        event.target.value === 'string' ? event.target.value.split(',') : event.target.value;

      handleFilters(
        'center',
        countryIds.filter((x) => configs.centers.findIndex((y) => y.name === x) >= 0)
      );
    },
    [onFilters]
  );
  const handleFilterPayment = useCallback(
    (event) => {
      handleFilters(
        'payment',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );
  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Typography variant="subtitle2">Filtreler</Typography>
        {skipCountry !== true && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 300, md: 200 },
            }}
          >
            <InputLabel>Ülke</InputLabel>

            <Select
              multiple
              value={filters.country}
              onChange={handleFilterRole}
              input={<OutlinedInput label="Ülke" />}
              renderValue={(selected) => {
                return selected
                  .filter((value) => countries.find((y) => y.id === value) !== undefined)
                  .map((value) => countries.find((y) => y.id === value).name)
                  .join(', ');
              }}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {roleOptions.map((option) => {
                let country = configs.countries.find((x) => x.id == option);
                return (
                  <MenuItem key={option} value={country.id}>
                    <Checkbox
                      disableRipple
                      size="small"
                      checked={filters.country.includes(country.id)}
                    />
                    {country.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>Şehir</InputLabel>

          <Select
            multiple
            value={filters.center}
            onChange={handleFilterCity}
            input={<OutlinedInput label="Şehir" />}
            renderValue={(selected) => {
              return selected.join(', ');
            }}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {centerOptions.map((center) => {
              return (
                <MenuItem key={center} value={center}>
                  <Checkbox disableRipple size="small" checked={filters.center.includes(center)} />
                  {center}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>Randevu durumu</InputLabel>

          <Select
            multiple
            value={filters.payment}
            onChange={handleFilterPayment}
            input={<OutlinedInput label="Role" />}
            renderValue={(selected) => selected.map((value) => value).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {paymentOptionsFilters.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox disableRipple size="small" checked={filters.payment.includes(option)} />
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  );
}

CountryDatesFilters.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  roleOptions: PropTypes.array,
};
