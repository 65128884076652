import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import { NavSectionVertical } from 'src/components/nav-section';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
//
import { GlobalContext } from 'src/context/GlobalProvider';
import { NavToggleButton, NavUpgrade } from '../_common';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { isAuthenticated } from 'src/context/AuthContext';
import { paths } from 'src/routes/paths';
import { isAdminContext, isAuthenticatedUser } from 'src/auth/hooks/auth-hooks';

// ----------------------------------------------------------------------

export default function NavVertical({ openNav, onCloseNav }) {
  const { userData } = useContext(GlobalContext);

  const pathname = usePathname();
  let isAuth = isAuthenticatedUser();
  let isAdmin = isAdminContext();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();
  if (pathname === '/randevuTakip/genel' || pathname === '/randevuTakip/bildirim') {
    navData
      .find((x) => x.subheader == 'Randevu Takibi')
      .items.find((x) => x.title == 'Ülkeler').path = '';
  } else {
    navData
      .find((x) => x.subheader == 'Randevu Takibi')
      .items.find((x) => x.title == 'Ülkeler').path = paths.countryTracking.countries.root;
  }
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />

      <NavSectionVertical
        data={
          navData
            ? navData.filter(
                (x) =>
                  x.requireAuth === false ||
                  x.requireAuth === undefined ||
                  (x.requireAuth === true &&
                    isAuth &&
                    (x.onlyAdmin === false ||
                      x.onlyAdmin === undefined ||
                      (x.onlyAdmin === true && isAdmin)))
              )
            : []
        }
        config={{
          currentRole: userData?.role || 'admin',
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

      <NavUpgrade />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
};
