// Import the functions you need from the SDKs you need
import { initializeApp, getApps, f } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyB9Txg49JrbXSoFaUVEaebANAIAtt_G67k',
  authDomain: 'vizedefteri-f9051.firebaseapp.com',
  projectId: 'vizedefteri-f9051',
  storageBucket: 'vizedefteri-f9051.appspot.com',
  messagingSenderId: '612967443747',
  appId: '1:612967443747:web:eaf511ce61c0e1c17e3096',
  measurementId: 'G-KJPCL6R07B',
};
// Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
const analytics = getAnalytics(firebase_app);
export default firebase_app;
