import { Helmet } from 'react-helmet-async';
// sections
import { Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useSettingsContext } from 'src/components/settings';
import { GlobalContext } from 'src/context/GlobalProvider';
import MainContainer from 'src/pages/mainContainer';
import { ChatView } from './view';
import CustomerService from 'src/api/CustomerService';
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export default function CustomerChatPage() {
  const { configs } = useContext(GlobalContext);

  const settings = useSettingsContext();
  const { customerList } = useContext(GlobalContext);
  const { getCustomers } = CustomerService();

  useEffect(() => {
    if (customerList == null) getCustomers();
  }, []);
  if (customerList == null || customerList == undefined) return <LoadingScreen />;
  return (
    <>
      <Helmet>
        <title> Mesajlar</title>
      </Helmet>
      <Typography variant="h4">Mesajlaşma ( Whatsapp + Email)</Typography>
      <MainContainer>
        <ChatView />
      </MainContainer>
    </>
  );
}
