import { m } from 'framer-motion';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
import { fPercent } from 'src/utils/format-number';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { MotionViewport, varFade } from 'src/components/animate';
import { List, ListItem } from '@mui/material';

// ----------------------------------------------------------------------

export const SKILLS = [...Array(3)].map((_, index) => ({
  label: ['Development', 'Design', 'Marketing'][index],
  value: [20, 40, 60][index],
}));

// ----------------------------------------------------------------------

export default function AboutWhat() {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 10, md: 15 },
        textAlign: { xs: 'center', md: 'unset' },
      }}
    >
      <Grid container columnSpacing={{ md: 3 }} alignItems="flex-start">
        {mdUp && (
          <Grid container xs={12} md={6} lg={7} alignItems="center" sx={{ pr: { md: 7 } }}>
            <Grid xs={6}>
              <m.div variants={varFade().inUp}>
                <Image
                  alt="our office 2"
                  src="/assets/images/about/what_2.png"
                  ratio="1/1"
                  sx={{ borderRadius: 3, boxShadow: shadow }}
                />
              </m.div>
            </Grid>

            <Grid xs={6}>
              <m.div variants={varFade().inUp}>
                <Image
                  alt="our office 1"
                  src="/assets/images/about/what_1.png"
                  ratio="3/4"
                  sx={{ borderRadius: 3, boxShadow: shadow }}
                />
              </m.div>
            </Grid>
            <Stack alignItems="center">
              <Typography variant="h3" noWrap>
                KURUMSAL ÜYELİK İÇİN
              </Typography>
              <Typography variant="subtitle1" noWrap>
                7/24 whatsapp ve mail üzerinden ulaşabilirsiniz.
              </Typography>
              <Stack direction={'row'} spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
                <Iconify icon="solar:phone-bold" />
                <Typography variant="subtitle2" noWrap>
                  +49 192 701 5066
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
                <Iconify icon="fluent:mail-24-filled" />

                <Typography variant="subtitle2" noWrap>
                  vizedefteri@gmail.com
                </Typography>
              </Stack>

              <Button variant="contained" target="_blank" rel="noopener">
                Vize defterine ulaş
              </Button>
            </Stack>
          </Grid>
        )}

        <Grid xs={12} md={6} lg={5}>
          <m.div variants={varFade().inRight}>
            <Typography variant="h2" sx={{ mb: 3 }}>
              Vizedefterim nedir?
            </Typography>
          </m.div>
          <m.div variants={varFade().inRight}>
            <Typography
              sx={{
                color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
              }}
            >
              Vize defterim sadece bir randevu takip programı değildir. Randevu takibi vize
              danışmanlarına sunduğumuz hizmetlerden sadece birisidir.
            </Typography>
            <Typography
              sx={{
                color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
              }}
            >
              Vize defterim sadece bir randevu takip programı değildir. Randevu takibi vize
              danışmanlarına sunduğumuz hizmetlerden sadece birisidir.
            </Typography>
            <List sx={{ listStyleType: 'disc' }}>
              <ListItem sx={{ display: 'list-item' }}>Randevu takip sistemi</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Müşteri takip sistemi</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Kurumsal numara</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Kişisel ve ekip takvimi</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Ekip yönetimi</ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                Dosya yükleme arayüzü (Müşteriler için)
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                Email & Whatsapp tek bir yerden müşteriler ile konuşma yönetme
              </ListItem>
              <ListItem>Ve dahası</ListItem>
            </List>
          </m.div>
        </Grid>
      </Grid>
    </Container>
    // </Box>
  );
}
