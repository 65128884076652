import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';
import firebase_app from 'src/firebase-app';
import { getAuth } from 'firebase/auth';
import { isAdminContext } from '../hooks/auth-hooks';

// ----------------------------------------------------------------------

export default function AdminGuard({ children }) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const auth = getAuth(firebase_app);
  let authenticated = isAdminContext();
  const returnTo = searchParams.get('returnTo') || paths.countryTracking.countryDashboard;

  const check = useCallback(() => {
    if (!authenticated) {
      router.replace(returnTo);
    }
  }, [authenticated, returnTo, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}

AdminGuard.propTypes = {
  children: PropTypes.node,
};
