import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';
import NonAuthGuard from 'src/auth/guard/NonAuthGuard';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'girisYap',
    element: (
      <NonAuthGuard>
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      </NonAuthGuard>
    ),
  },
  {
    path: 'kaydol',
    element: (
      <NonAuthGuard>
        <AuthClassicLayout title="Saniyeler içinde üye olarak takip etmeye başla">
          <JwtRegisterPage />
        </AuthClassicLayout>
      </NonAuthGuard>
    ),
  },
];
