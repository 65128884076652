import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { logout, setToken, setOrganisationId } from '../context/AuthContext';
import { GlobalContext } from '../context/GlobalProvider';
import { useAuthState } from 'react-firebase-hooks/auth';

import { signInWithEmailAndPassword } from 'firebase/auth';

import {
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  signOut,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import firebase_app from '../firebase-app';
import {
  getFirestore,
  doc,
  setDoc,
  getDocs,
  collection,
  updateDoc,
  getDoc,
  arrayUnion,
  arrayRemove,
  runTransaction,
  deleteField,
} from 'firebase/firestore';
import { getFirebaseUser, isAuthenticatedUser } from 'src/auth/hooks/auth-hooks';
import { getAnalytics, logEvent } from 'firebase/analytics';
import axiosInstance from 'src/utils/axios';

export default function UserAuth() {
  const auth = getAuth(firebase_app);
  const [user, loading] = useAuthState(auth);

  let { setUserData, userData, searchConfigs, configs } = useContext(GlobalContext);

  const loginUser = async (data, errorMsg) => {
    const { username, password } = data;
    let result = await signInWithEmailAndPassword(auth, username, password);
  };
  const logout = async (data, errorMsg) => {
    if (isAuthenticatedUser() === false) return;
    signOut(auth).then(() => {
      localStorage.removeItem('userData');
      setUserData(null);
    });
  };
  const anonymousLogin = async () => {
    await signInAnonymously(auth);
  };
  const register = async (email, password) => {
    try {
      let result = await createUserWithEmailAndPassword(auth, email, password);
    } catch (e) {
      if (e.code == 'auth/email-already-in-use')
        throw new Error('Bu email adresi ile kayıtlı bir kullanıcı var. Lütfen giriş yap.');
      else {
      }
    }
  };

  const getUserData = async (user) => {
    const idTokenResult = await user.getIdTokenResult();
    let isAdmin = idTokenResult.claims.isAdmin === true;
    let data = {
      organisationId: 1,
      username: user.email,
      companyName: 'demoCompany',
      isAdmin: isAdmin,
      email: user.email,
      mailList: [],
      isAuthenticated: user.isAnonymous === false,
    };
    if (user.isAnonymous === false) {
      data.mailList = [{ mail: user.email, name: user.email }];
    }
    if (data.isAuthenticated !== true) return data;

    let collectionName = 'Users';
    const db = getFirestore(firebase_app);
    const doc2 = await getDoc(doc(db, collectionName + '/' + user.uid));

    return { ...data, remoteData: doc2.data() };
  };
  const followDate = async (configId) => {
    const analytics = getAnalytics();

    let remoteData = { ...userData.remoteData };
    if (remoteData === undefined || remoteData === null) {
      remoteData = {};
    }
    if (remoteData.trackedConfig !== undefined) {
      return;
    }
    remoteData.trackedConfig = configId;

    const db = getFirestore(firebase_app);
    const userDoc = doc(db, 'Users/' + user.uid);
    const trackerDoc = doc(db, 'Tracker/' + configId);
    await runTransaction(db, async function (transaction) {
      await transaction.set(userDoc, remoteData);
      await transaction.update(trackerDoc, {
        EmailsToAlert: arrayUnion(userData.email),
      });
    })
      .then(async (e) => {
        let d = { ...userData };
        d.remoteData = remoteData;
        setUserData(d);
        logEvent(analytics, 'date_followed', {
          config_id: configId,
        });
        try {
          await sendConfigFollowEmail(configId, true);
        } catch (es) {
          console.log(es);
        }
      })
      .catch(async (e) => {
        delete userData.remoteData.trackedConfig;
        if (e.code === 'not-found') {
          await setDoc(doc(db, 'Tracker/' + configId), {
            EmailsToAlert: [userData.email],
          });

          return await followDate(configId);
        }
      });
  };

  const sendConfigFollowEmail = async (configId, isFollowed) => {
    const searchConfig = searchConfigs.find((x) => x.Id == configId);
    let country = configs.countries.find((x) => x.id == searchConfig.countryId);
    let visaCategory = configs.visaCategories.find((x) => x.id == searchConfig.visaCategoryId);
    let center = configs.centers.find((x) => x.id == searchConfig.cityCenterId);
    let title, text;
    if (isFollowed) {
      title =
        country.name + ' için randevu takibiniz başlamıştır. Yer açıldığında bildirim alacaksınız.';
      text =
        'Merhabalar, ' +
        country.name +
        ' için randevu takibiniz başlamıştır. Yer açıldığında bildirim alacaksınız.' +
        '\n\n\n Vize kategoririsi:' +
        visaCategory.name +
        '\n\n Merkez:' +
        center.name;
    } else {
      title = country.name + ' için randevu takibini bıraktınız.';
      text =
        'Merhabalar, ' +
        country.name +
        ' için randevu takibini bıraktınız.' +
        '\n\n\n Vize kategoririsi:' +
        visaCategory.name +
        '\n\n Merkez:' +
        center.name;
    }
    await axiosInstance.post('https://startscraping-g4qqkuhnra-uc.a.run.app', {
      email: userData.email,
      text: text,
      title: title,
    });
  };
  const unfollowDate = async () => {
    let remoteData = userData.remoteData;
    if (!remoteData.trackedConfig) {
      return;
    }

    let configId = remoteData.trackedConfig;
    const db = getFirestore(firebase_app);

    const userDoc = doc(db, 'Users/' + user.uid);
    const trackerDoc = doc(db, 'Tracker/' + configId);

    await runTransaction(db, async function (transaction) {
      await transaction.update(userDoc, { trackedConfig: deleteField() });
      await transaction.update(trackerDoc, {
        EmailsToAlert: arrayRemove(userData.email),
      });
    })
      .then(async (e) => {
        let d = { ...userData };
        delete d.remoteData.trackedConfig;
        setUserData(d);
        try {
          await sendConfigFollowEmail(configId, false);
        } catch (es) {
          console.log(es);
        }
      })
      .catch((e) => {});
  };

  return {
    loginUser,
    logout,
    getUserData,
    anonymousLogin,
    register,
    followDate,
    unfollowDate,
  };
}
