import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import App from './App';
import CombinedContextProviders from './context/CombinedContextProviders';
import firebase_app from './firebase-app';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-KJPCL6R07B'); //Measurement_Id e.g(G-F6B4XXXXXX)

Sentry.init({
  dsn: 'https://5de81de9217ad08b29cd1a0ef2510b0f@o4507225326616576.ingest.de.sentry.io/4507225329827920',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const tagManagerArgs = {
  gtmId: 'G-KJPCL6R07B',
};

TagManager.initialize(tagManagerArgs);
// ----------------------------------------------------------------------
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
